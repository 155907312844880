<template>
  <nav class="header-nav">
    <div class="container-padding">
      <div class="header-nav__inner">
        <ul class="header-nav__links">
          <li v-for="(link, i) in links" :key="i" class="header-nav__link">
            <div
              :class="{
                'header-nav__link-line--active': linkActive(link),
              }"
              class="header-nav__link-line"
            />
            <router-link :to="link.route" class="header-nav__link-item">
              <IconComponent v-if="link.icon" :name="link.icon" />
              <span>{{ link.title }}</span>
              <IconComponent name="arrow_down" v-if="link.subLinks && link.subLinks.length" />
            </router-link>
            <ul v-if="link.subLinks && link.subLinks.length" class="header-nav__subLinks">
              <li v-for="(subLink, i) in link.subLinks" :key="i">
                <router-link :to="subLink.route">
                  <span class="header-nav__subLink-title">{{ subLink.title }}</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "HeaderNavComponent",
  computed: {
    links() {
      return [
        {
          title: "Правительство",
          icon: "government",
          route: { name: "persons" },
          subLinks: this.$store.state.activities_department
            ? [
                ...[
                  {
                    title: "Структура правительства",
                    route: { name: "persons" },
                  },
                  {
                    title: "Органы исполнительной власти",
                    route: { name: "sub_departments" },
                  },
                ],
                ...this.$store.state.activities_department.map((item) => ({
                  title: item.title,
                  route: {
                    name: "activity",
                    params: { id: item.id },
                  },
                })),
              ]
            : [
                {
                  title: "Структура правительства",
                  route: { name: "persons" },
                },
                {
                  title: "Органы исполнительной власти",
                  route: { name: "sub_departments" },
                },
              ],
        },
        {
          title: "Документы",
          icon: "briefcase",
          route: { name: "documents" },
        },
        {
          title: "Пресс-центр",
          icon: "newspaper",
          route: { name: "press" },
          subLinks: [
            {
              title: "Новости",
              route: { name: "news" },
            },
            {
              title: "Анонсы",
              route: {
                name: "news",
                query: { type: JSON.stringify({ id: 3, title: "Анонсы", __typename: "GovernmentNewsType" }) },
              },
            },
          ],
        },
        {
          title: "Проектный офис",
          icon: "project",
          route: { name: "projects" },
          subLinks: this.$store.state.activities_project
            ? this.$store.state.activities_project.map((item) => ({
                title: item.title,
                route: {
                  name: "activity",
                  params: { id: item.id },
                },
              }))
            : [],
        },
        {
          title: "Контакты",
          icon: "contacts",
          route: { name: "contacts" },
          subLinks: this.$store.state.activities_contacts
            ? this.$store.state.activities_contacts.map((item) => ({
                title: item.title,
                route: {
                  name: "activity",
                  params: { id: item.id },
                },
              }))
            : [],
        },
      ];
    },
  },
  methods: {
    linkActive(link) {
      if (this.$route.name === link["route"].name) {
        return true;
      }
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.header-nav {
  background var(--white)
  display flex
  align-items center

  +below(850px) {
    display none
  }

  &__inner {
    display flex
    justify-content center
  }

  &__links {
    display flex
    align-items center
    justify-content center
    flex-wrap wrap
    gap 0 50px
  }

  &__link {
    position relative

    &:hover {
      .header-nav__link-line {
        opacity 1
        visibility visible
        transition var(--transition)
      }

      .header-nav__subLinks {
        opacity 1
        visibility visible
        transition var(--transition)
      }

      .header-nav__link-item {
        color var(--main)

        .arrow_down-icon svg {
          transform scaleY(-1)
          transition var(--transition)
        }
      }
    }
  }

  &__link-line {
    background var(--main)
    height 5px
    width 100%
    position absolute
    top 0
    visibility hidden
    opacity 0

    &--active {
      visibility visible
      opacity 1
    }
  }

  &__link-item {
    display flex
    align-items center
    gap 5px
    padding 17px 0

    span {
      line-height 1.625rem
    }

    .icon svg {
      max-width 25px
      max-height 25px
      padding 0 5px 0 0
    }

    .arrow_down-icon svg {
      max-width 16px
      max-height 16px

      path {
        fill var(--dark_o5)
      }
    }
  }

  &__subLinks {
    display flex
    flex-direction column
    gap 20px
    visibility hidden
    opacity 0
    z-index 10
    width 250px
    absolute top 60px left 0
    padding 10px 25px
    background var(--white)
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
  }
}
</style>
