export default {
  namespaced: true,
  state: () => ({
    slider_news: {},
    last_news: {},
    minister_news: {},
    department_news: {},
    exception_news: {},
    projects_paginate: {},
    links: [],
    order_documents: {},
    resolution_documents: {},
    // acts: {
    //   data: [],
    // },
  }),
  actions: {
    save({ state }, data) {
      if (data) {
        Object.keys(data).forEach((key) => {
          state[key] = data[key];
        });
      }
    },
  },
};
